//This css remove focus
*:focus {
  box-shadow: none !important;
  outline: none;
}

*[data-focus] {
  box-shadow: none !important;
  outline: none;
}
.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

html {
  font-size: 7px;
  overflow: hidden;
  color: var(--main-text-color);
  @media screen and (min-width: 280px) {
    font-size: 7.5px;
  }
  @media screen and (min-width: 320px) {
    font-size: 8.5px;
  }
  @media screen and (min-width: 375px) {
    font-size: 10px;
  }
}

.mask-image {
  -webkit-mask-image: url("../../../public/img/mask.png");
  -webkit-mask-size: contain;
  -webkit-mask-position: custom;
  -webkit-mask-position-x: 0%;
  -webkit-mask-position-y: 2%;
  -webkit-mask-repeat: no-repeat;
  width: 80%;
  vertical-align: middle;
  display: inline-block;
}

.custom-shape-divider-bottom-1687279438 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1687279438 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1687279438 .shape-fill {
  fill: rgba(246, 142, 19, 0.2);
}

.round-profile img {
  object-position: top;
}
.swiper {
  width: 100%;
  height: 25rem;
  & .swiper-pagination {
    bottom: 0 !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
